import { Icon } from '@iconify/react/dist/iconify.js';
import { forwardRef, useEffect, useImperativeHandle, useRef, InputHTMLAttributes } from 'react';

export default forwardRef(function TextInput(
    { type = 'text', className = '', isFocused = false, ...props }: InputHTMLAttributes<HTMLInputElement> & { isFocused?: boolean },
    ref
) {
    const localRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => ({
        focus: () => localRef.current?.focus(),
    }));

    useEffect(() => {
        if (isFocused) {
            localRef.current?.focus();
        }
    }, []);

    return (
        <div className='relative mb-6'>
            <input
                {...props}
                type={type}
                className={
                    'border-i-navy text-i-gray rounded-sm pl-4 pr-12 py-3 text-16 leading-6 tracking-[0.012em] focus:ring-i-blue transition-all ease-in-out duration-300 w-full ' +
                    className
                }
                ref={localRef}
            />

            <Icon icon="ic:outline-edit" className='text-i-indigo absolute top-1/2 transform -translate-y-1/2 right-4 pointer-events-none' width={24} height={24}/>
        </div>
    );
});
