import { usePage } from "@inertiajs/react";
import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { Transition } from "@headlessui/react";
import { InertiaPage } from "@/types/inertia";
import { FormAlertProps } from "@/types/components/front/form/formAlert";

const FormAlert = ({ errors }: FormAlertProps) => {
    const [visible, setVisible] = useState<boolean>(true);
    const { success } = usePage<InertiaPage>().props?.flash;

    setTimeout(() => setVisible(false), 3000)

    return (
        <Transition
            show={visible}
            enter="transition-opacity duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            {((errors !== undefined && Object.keys(errors).length > 0) ||
                success) && (
                <div
                    className={`fixed left-4 bottom-6 flex gap-3 p-3 rounded-md max-w-xs z-50 shadow-lg ${
                        success &&
                        "bg-green-100 border-green-700 border-2 text-green-700"
                    } ${
                        errors !== undefined &&
                        Object.keys(errors).length > 0 &&
                        "bg-red-100 border-red-700 border-2 text-red-700"
                    }`}
                >
                    {success && (
                        <>
                            <div>
                                <Icon
                                    icon="material-symbols:check-circle-outline-rounded"
                                    width={25}
                                    height={25}
                                    className="mt-0.5"
                                />
                            </div>
                            <div>
                                <span className="font-extrabold text-18 mr-2">
                                    Sukces!
                                </span>
                                <span className="text-18">{success}</span>
                            </div>
                        </>
                    )}
                    {errors !== undefined && Object.keys(errors).length > 0 && (
                        <>
                            <div>
                                <Icon
                                    icon="ic:baseline-warning-amber"
                                    width={25}
                                    height={25}
                                    className="mt-0.5"
                                />
                            </div>
                            <div>
                                <span className="font-extrabold text-18 mr-2">
                                    Błąd!
                                </span>
                                {Object.keys(errors).map((key, index) => {
                                    return (
                                        <span className="text-18" key={index}>
                                            {errors[key]}
                                        </span>
                                    );
                                })}
                            </div>
                        </>
                    )}
                </div>
            )}
        </Transition>
    );
};
export default FormAlert;
