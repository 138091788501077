import Container from "@/Components/Backend/Grid/Container";
import ClassicButton from "../../Buttons/ClassicButton";
import Text from "../../Text";
import Row from "@/Components/Backend/Grid/Row";
import Column from "@/Components/Backend/Grid/Column";
import TextInput from "../../Form/TextInput";
import { Link, router, useForm, usePage } from "@inertiajs/react";
import Label from "../../Form/Label";
import TextArea from "../../Form/TextArea";
import Acceptance from "../../Form/Acceptance";
import FormAlert from "./FormAlert";

const ContactForm = ({ heading = 'Kontakt', headingPosition = 'left' }: any) => {
    const { data, reset, wasSuccessful, setData, post, processing, errors } =
        useForm<{
            name: string;
            surname: string;
            email: string;
            phone: string;
            message: string;
            marketing_acceptance: boolean;
        }>({
            name: "",
            surname: "",
            email: "",
            phone: "",
            message: "",
            marketing_acceptance: false,
        });

    function submit(e: { preventDefault: () => void }) {
        e.preventDefault();
        
        post(route("send-contact-form"), {
            preserveScroll: true,
            onSuccess: () => {
                window.dataLayer.push({
                    event: 'formularz osiedleidea LP',
                    telephone: data.phone,
                    mail: data.email
                })

                reset();

                router.visit(route('front.contact.thankYou'))
            }
        });

        
    }

    const handleScrollToFormButton = () => {
        const element = document.getElementById('contact-form-main-wrapper');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    
    return (
        <div className="py-12 bg-i-blue-to-indigo">
            {errors !== undefined &&
                Object.keys(errors).length > 0 &&
                !processing && <FormAlert errors={errors} />}
            {/* {wasSuccessful && <FormAlert />} */}
            {/* <button onClick={handleScrollToFormButton} className="px-4 py-[10.5px] bg-i-blue items-center text-white fixed z-20 right-0 bottom-[90px] flex gap-2 -rotate-90 translate-x-full origin-[0_100%] hover:bg-i-yellow duration-200 [&:hover_.hover-fill-navy]:fill-i-navy hover:text-i-navy ease-in-out transition-all shadow-[-8px_0px_32px_0px_rgba(23,37,79,0.12)]"> */}
            <button onClick={handleScrollToFormButton} className="px-4 py-[10.5px] bg-i-blue items-center text-white fixed z-20 left-0 bottom-[90px] translate-x-[45px] origin-[0_100%] flex gap-2 -rotate-90  hover:bg-i-yellow duration-200 [&:hover_.hover-fill-navy]:fill-i-navy hover:text-i-navy ease-in-out transition-all shadow-[-8px_0px_32px_0px_rgba(23,37,79,0.12)]">
                <svg width="32" height="27" className="rotate-90" viewBox="0 0 32 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.23505 5.24699L5.23549 5.24655C9.5789 0.884492 16.7171 0.906537 21.1835 5.38536L24.6106 8.86371C24.6113 8.86443 24.612 8.86516 24.6128 8.86589C26.013 10.2954 27.5009 11.8203 28.6394 12.9882C28.834 13.1879 29.0184 13.3771 29.1903 13.5537C28.5819 14.162 27.8198 14.9242 26.9973 15.7471C25.0304 17.7147 22.7164 20.0307 21.3319 21.4203C16.9837 25.7844 9.84568 25.7605 5.3798 21.2783C0.90979 16.7919 0.886157 9.61181 5.23505 5.24699Z" fill="white" stroke="white" strokeWidth="4"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M5.29037 10.0586C4.87773 11.1014 4.66809 12.203 4.66676 13.3333C4.66478 14.4564 4.87665 15.569 5.29037 16.608C5.71364 17.6755 6.33327 18.6336 7.13202 19.4564L7.15856 19.4837C7.9666 20.3078 8.90468 20.9461 9.95023 21.378C10.9559 21.7948 12.0307 22.006 13.1147 21.9999C14.2054 21.9944 15.2682 21.7771 16.2726 21.3493C17.3128 20.9065 18.2483 20.26 19.0483 19.4276C19.9653 18.4738 20.6552 17.3141 21.0638 16.0395L23.0806 13.962C23.2425 13.7953 23.3334 13.5691 23.3334 13.3333C23.3334 13.0975 23.2425 12.8714 23.0806 12.7046L21.0625 10.6258C20.6538 9.35115 19.964 8.19142 19.047 7.23762L19.0192 7.20891C18.2244 6.39023 17.2996 5.75469 16.2726 5.31733C15.271 4.89121 14.1984 4.67024 13.1147 4.66676C12.0241 4.66129 10.9586 4.87041 9.95023 5.28863C8.90468 5.72053 7.9666 6.3588 7.15856 7.18295C6.34654 8.0112 5.71894 8.98022 5.29037 10.0586ZM8.90917 8.99999L8.90917 17.6667C8.90917 18.1453 9.28905 18.5333 9.75766 18.5333C10.2263 18.5333 10.6061 18.1453 10.6061 17.6667L10.6061 8.99999C10.6061 8.52135 10.2263 8.13333 9.75766 8.13333C9.28905 8.13333 8.90917 8.52135 8.90917 8.99999ZM12.3031 17.6667L12.3031 9.86666C12.3031 9.38801 12.683 8.99999 13.1516 8.99999C13.6202 8.99999 14.0001 9.38801 14.0001 9.86666L14.0001 17.6667C14.0001 18.1453 13.6202 18.5333 13.1516 18.5333C12.683 18.5333 12.3031 18.1453 12.3031 17.6667ZM15.6971 12.4667L15.6971 17.6667C15.6971 18.1453 16.0769 18.5333 16.5455 18.5333C17.0141 18.5333 17.394 18.1453 17.394 17.6667L17.394 12.4667C17.394 11.988 17.0141 11.6 16.5455 11.6C16.0769 11.6 15.6971 11.988 15.6971 12.4667Z" className="hover-fill-navy fill-i-blue duration-200 ease-in-out transition-all"/>
                </svg>
                <Text heading="label" bold className="flex items-center duration-200 ease-in-out transition-all" color="inherit">
                    <span>
                        Skontaktuj się z nami
                    </span>
                </Text>
            </button>
            <form onSubmit={submit} id="contact-form-main-wrapper">
                <Container size="small">
                    <Row>
                        <Column>
                            <Text heading="h2" className={`mb-6 ${headingPosition == 'left' ? 'text-left' : 'text-center'}`} color="white">
                                {heading}
                            </Text>
                        </Column>
                    </Row>
                    <Row>
                        <Column xl={6} lg={6} md={6}>
                            <Label value="Imię*" htmlFor="name" />
                            <TextInput
                                required
                                name="name"
                                id="name"
                                value={data.name}
                                onChange={(e) =>
                                    setData("name", e.target.value)
                                }
                            />

                            <Label value="Nazwisko*" htmlFor="surname" />
                            <TextInput
                                required
                                name="surname"
                                id="surname"
                                value={data.surname}
                                onChange={(e) =>
                                    setData("surname", e.target.value)
                                }
                            />
                        </Column>

                        <Column xl={6} lg={6} md={6}>
                            <Label value="Adres email*" htmlFor="email" />
                            <TextInput
                                required
                                name="email"
                                id="email"
                                value={data.email}
                                onChange={(e) =>
                                    setData("email", e.target.value)
                                }
                            />

                            <Label value="Numer telefonu" htmlFor="phone" />
                            <TextInput
                                name="phone"
                                id="phone"
                                value={data.phone}
                                onChange={(e) =>
                                    setData("phone", e.target.value)
                                }
                            />
                        </Column>

                        <Column>
                            <Label value="Napisz jakiego mieszkania szukasz" htmlFor="message" />
                            <TextArea
                                id="message"
                                name="message"
                                required
                                value={data.message}
                                onChange={(e) =>
                                    setData("message", e.target.value)
                                }
                            />
                        </Column>

                        <Column>
                            <Row className="justify-between">
                                <Column xl={7} lg={7} md={7}>
                                    <Text
                                        heading="label"
                                        className="mb-4"
                                        color="white"
                                    >
                                        Jeśli dodatkowo chcesz otrzymywać od nas
                                        na stałe również inne oferty, możesz
                                        również wyrazić zgodę:
                                    </Text>
                                    <Acceptance
                                        id="marketing_acceptance"
                                        name="marketing_acceptance"
                                        checked={data.marketing_acceptance}
                                        onChange={(e) =>
                                            setData(
                                                "marketing_acceptance",
                                                e.target.checked ? true : false
                                            )
                                        }
                                    >
                                        Wyrażam zgodę na otrzymywanie od
                                        Unidevelopment S.A.{" "}
                                        <strong>
                                            drogą elektroniczną na mój adres
                                            e-mail
                                        </strong>{" "}
                                        ofert sprzedaży i najmu lokali oraz
                                        innych związanych z tym usług
                                        oferowanych przez Unidevelopment S.A.
                                        oraz inne podmioty z grupy
                                        Unidevelopment S.A.
                                    </Acceptance>

                                    <Text
                                        heading="label"
                                        className="mt-4"
                                        color="white"
                                    >
                                        Przysługuje Pani/Panu prawo do cofnięcia
                                        powyższej w dowolnym momencie, bez
                                        wpływu na zgodność z prawem
                                        przetwarzania dokonanego na podstawie
                                        zgody przed jej cofnięciem.
                                    </Text>
                                </Column>
                                <Column xl={4} lg={4} md={4}>
                                    <Text heading="label" color="white">
                                        Przesyłając wiadomość zapoznaj się z{" "}
                                        <a
                                            href="https://unidevelopment.pl/node/70"
                                            target="_blank"
                                            className="text-i-yellow border-b border-b-transparent hover:border-b hover:border-b-i-yellow transition-all ease-in-out duration-300"
                                        >
                                            Polityką prywatności.
                                        </a>
                                    </Text>
                                </Column>
                            </Row>
                        </Column>
                    </Row>
                    <Row className="justify-center">
                        <Column xl={7} lg={7} md={7}>
                            <ClassicButton
                                full
                                className="mt-6 border-2 border-white"
                                type="submit"
                                title="Wyślij wiadomość"
                                disabled={processing}
                            >
                                Wyślij wiadomość
                            </ClassicButton>
                        </Column>
                    </Row>
                </Container>
            </form>
        </div>
    );
};

export default ContactForm;
