import { InputHTMLAttributes } from 'react';
import Text from '../Text';

const Acceptance = ({ className = '', name, color='white', children, ...props }: InputHTMLAttributes<HTMLInputElement>) => {
    return (
        <label htmlFor={name} className='flex gap-4 flex-nowrap cursor-pointer'>
            <input
                {...props}
                name={name}
                type="checkbox"
                className={
                    'rounded-sm transition-all duration-200 ease-in-out focus:ring-0 border-i-blue border-2 bg-transparent checked:bg-i-blue focus:checked:bg-i-blue checked:hover:bg-i-blue h-6 w-6 ' +
                    className
                }
            />

            <Text heading='label' color={color}>
                    {children}
            </Text>
        </label>
    );
}

export default Acceptance