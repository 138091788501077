import { LabelHTMLAttributes } from 'react';
import Text from '../Text';

const Label = ({ value, className = '', children, color = 'white', ...props }: LabelHTMLAttributes<HTMLLabelElement> & { value?: string }) => {
    return (
        <label {...props} className={`block mb-2 ` + className}>
            <Text heading='label' color={color} bold>
                {value ? value : children}
            </Text>
        </label>
    );
}

export default Label;
