import { TextAreaProps } from "@/types/components/front/form/textArea";
import { Icon } from "@iconify/react/dist/iconify.js";

const TextArea = ({
    name,
    id,
    value = "",
    required = false,
    rows = 7,
    className = "",
    onChange,
}: TextAreaProps) => {
    return (
        <div className="relative mb-6">
            <textarea
                name={name}
                id={id}
                rows={rows}
                className={
                    "border-i-navy text-i-gray rounded-sm pl-4 pr-12 py-3 text-16 min-h-[100px] leading-6 tracking-[0.012em] focus:ring-i-blue transition-all ease-in-out duration-300 w-full  " +
                    className
                }
                onChange={onChange}
                value={value}
                required={required}
            />

            <Icon
                icon="ic:outline-edit"
                className="text-i-indigo absolute top-[15px] transform right-4 pointer-events-none"
                width={24}
                height={24}
            />
        </div>
    );
};

export default TextArea;
